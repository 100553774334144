import React from "react";

export class HomeControl extends React.Component {
  // constructor(props) {
  //   super(props);

  // }

  render() {

    return (<>Welcome to admin console</>)

  }
}


export default HomeControl;