import React from "react";
import { Auth0Client } from '@auth0/auth0-spa-js';

const auth0 = new Auth0Client({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID
});

export class EditCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: "",
      company: {}
    };
  }

  submitForm(company) {
    auth0.getTokenSilently({
      audience: 'https://dev-tasks.pricingsaas.com'
    })
    .then(accessToken =>
    {
          fetch('https://dev-tasks.pricingsaas.com/scraper/updateCompany', {
            method: 'PUT',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
              Authorization: 'Bearer ' + accessToken,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(company)
          }).then(response => response.json())
            .then((data) => {
              this.setState({
                message : data.message
              });
              console.log(data)
            });
        }
      )
  }

  componentDidMount() {

    const queryParams = new URLSearchParams(window.location.search)
    const slug = queryParams.get("slug");
    this.setState({slug})

    auth0.getTokenSilently({
        audience: 'https://dev-tasks.pricingsaas.com'
      })
    .then(accessToken =>
      {

        fetch('https://dev-tasks.pricingsaas.com/scraper/getCompany?slug='+slug, {
          method: 'GET',
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            Authorization: 'Bearer ' + accessToken
          }
        }).then(response => response.json())
          .then((data) => {
            this.setState({
              company : data.company
            });
            console.log(data)
          });
      }
    )
  }

  getEditContent(company) {

    // todo - write edit code here.
    return <>
              <button type="button" onClick={()=>this.submitForm({name: 'test'})}>Submit!</button>
           </>
  }

  render() {
    const slug =  this.state.slug;
    const company = this.state.company;

    let content = "";
    if (!company ||  Object.keys(company).length === 0) {
      content = <>Loading company...</>
    } else {
      content = this.getEditContent(company);
    }

    return (<>
      <div className="flex flex-col pt-9">
        <div>Edit company: {slug}</div>
        <div>{content}</div>
      </div></>)

  }
}


export default EditCompany;