import React from "react";
import { Auth0Client } from '@auth0/auth0-spa-js';

const auth0 = new Auth0Client({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID
});

export class CompaniesControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = JSON.parse(window.localStorage.getItem('state')) || {

      companiesCount: 0,
      companies: []
    };
  }

  setState(state) {
    window.localStorage.setItem('state', JSON.stringify(state));
    super.setState(state);
  }

  componentDidMount() {
    if (!this.state.companiesCount) {
      auth0.getTokenSilently({
          audience: 'https://dev-tasks.pricingsaas.com'
        })
      .then(accessToken =>
        {

          fetch('https://dev-tasks.pricingsaas.com/scraper/admindata', {
            method: 'GET',
            mode: 'cors', // no-cors, *cors, same-origin
            headers: {
              Authorization: 'Bearer ' + accessToken
            }
          }).then(response => response.json())
            .then((data) => {
              this.setState({
                companiesCount : data.companies.length,
                companies : data.companies
              });
              console.log(data)
            });
        }
      )
    }
  }

  render() {

    console.log('render', this.state.companiesCount);

    if (this.state.companiesCount ) {
        return (
            <div className="p-9">
                {this.state.companies.map((company, index) => (
                    <div  key={index} className="company">{company.companyId} (<a href={"/editCompany?slug=" + company.companyId}>Edit</a>)</div>
                ))}
            </div>
          );
    } else {
        return (
            <div>
               Loading companies
            </div>
          );
    }
  }
}


export default CompaniesControl;